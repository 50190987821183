import { resolveVariables, stringToColor, cl, clt, recordExists } from '../util/AVAUtilities';
import { getPerson } from '../util/AVAPeople';
import { makeDate } from '../util/AVADateTime';
import { getGroup } from '../util/AVAGroups';

const AWS = require('aws-sdk');
const AVAIcon = process.env.REACT_APP_AVA_LOGO;

const dbClient = new AWS.DynamoDB.DocumentClient({
  apiVersion: '2012-08-10',
  region: "us-east-1",
  accessKeyId: process.env.REACT_APP_AVA_ID,
  secretAccessKey: process.env.REACT_APP_AVA_KEY
});

let customObj = {};
let activityObj = {};
let groupObj = {};

export default async (requestor, masterClient, screenStatus, subMenuData = null, forceRefresh = false) => {

  if (forceRefresh) {
    customObj = {};
    activityObj = {};
    groupObj = {};
    requestor = await getPerson(requestor.person_id, '*all', true);
  };

  let groupList = [];

  let numberOfRows = 1000;
  let sectionDetails = {};
  let activityHistory = {};

  function makeVersion(inStr) {
    let [vYr, vDay] = inStr.split(/\.(.*)/);
    return ((Number(vYr) % 100) * 100) + parseFloat(vDay);
  }

  function makeNumber(inStr) {
    let [vYr, vMo, vDay] = inStr.split(/\./g);
    return (20000000 + ((Number(vYr) % 100) * 10000) + (Number(vMo) * 100) + Number(vDay));
  }

  let sectionCheck = {};
  function alreadyInSection(pSec, pAct) {
    if (sectionCheck[pSec]) {
      if (sectionCheck[pSec].includes(pAct)) { return true; }
      else { sectionCheck[pSec].push(pAct); }
    }
    else { sectionCheck[pSec] = [pAct]; }
    return false;
  }

  let ava_version_number = makeVersion(process.env.REACT_APP_AVA_VERSION);
  let ava_env = window.location.href.split('//')[1].slice(0, 1).toUpperCase();
  let todays_numeric_date = makeDate(new Date()).numeric;

  // Main line

  let pPerson = requestor.person_id;
  if (!masterClient) { masterClient = requestor.client_id; };
  if (subMenuData) { return await handleSubMenu(subMenuData); }
  else {
    let returnMe = await buildMainMenu(pPerson);
    return returnMe;
  }


  // Functions

  async function handleSubMenu(pSubMenu, pActivities = null, pOverrides = null) {
    let returnArray = [];
    let [sectionColor, sectionIcon] = await getCustomizations(pSubMenu.menu_name);
    if (pOverrides) {
      if (pOverrides.color) { sectionColor = pOverrides.color; }
      if (pOverrides.icon) { sectionIcon = pOverrides.icon; }
    }
    let subActivities = [];
    if (pActivities) { subActivities.push(...pActivities); }
    else { subActivities = await getSubMenu(pSubMenu.event_id, pSubMenu.client_id); }
    let aL = subActivities.length;
    if (aL > 0) {
      for (let a = 0; a < aL; a++) {
        let pos = 100 + a;
        let subClient, subKey;
        if (subActivities[a].includes('//')) { [subClient, subKey] = subActivities[a].split('//'); }
        else {
          subClient = pSubMenu.client_id;
          subKey = subActivities[a];
        }
        let this_row =
          await addRow(
            `${subClient}//${subKey}`,   // activity_code
            pSubMenu.event_id,                  // this menu_id                      
            pSubMenu.parent,                    // parent menu_id
            pSubMenu.parent_name,               // parent menu name
            `ZZZZ_SUB-${pSubMenu.event_id}-${pos}`,  // sort position
            pSubMenu.menu_name,                 // this menu name
            sectionColor,                       // this menu color
            sectionIcon,                        // this menu icon
            `Sub-menu ${pSubMenu.event_id}`     // why is this row in the menu
          );
        if (this_row) { returnArray.push(this_row); }
      }
    }
    return returnArray;
  }

  async function getSubMenu(pEvent, pClient) {
    let queryObj = {
      KeyConditionExpression: 'client_event_id = :e',
      ExpressionAttributeValues: {
        ':e': `${pClient}~${pEvent}`
      },
      TableName: "ActivityEvent",
      IndexName: 'sequence-index',
    };
    let mRecs = await dbClient
      .query(queryObj)
      .promise()
      .catch(error => {
        cl({ 'Error reading ActivityEvent': error });
      });
    if (recordExists(mRecs) && (mRecs.Count > 0)) {
      for (let m = 0; m < mRecs.Items.length; m++) {
        mRecs.Items[m].resolved = await resolveVariables(`%%${mRecs.Items[m].sort_order}%%`, { client_id: masterClient, patient_id: pPerson, user_id: pPerson });
        mRecs.Items[m].resolved = mRecs.Items[m].resolved.replace(/%%/g,'');
      }
      mRecs.Items.sort((a, b) => {
        if (a.resolved > b.resolved) { return 1; }
        else { return -1; }
      });
      return (mRecs.Items.map(m => { return m.activity_code; }));
    }
    else {
      return [];
    }
  }

  async function buildMainMenu(pPerson) {
    let returnArray = [];
    let sectionSort = '';
    let sectionName = '';
    let sectionColor = '';
    let sectionIcon = '';
    let duplicateCheck = [];
    let menuStructure = [{ menuName: 'main', currentSection: '' }];

    // Get Favorites from the People record
    // ({ '** FAVORITES **': (requestor.favorite_activities || 'no favorite activities') });
    sectionSort = '**2';
    sectionName = `${requestor.name.first.trim()}'${requestor.name.first.trim().slice(-1) === 's' ? '' : 's'} favorites`;
    sectionColor = '#6bb44b';
    sectionIcon = 'https://ava-icons.s3.amazonaws.com/icons8-favorite-50.png';
    let aL = 0;
    if (requestor.hasOwnProperty('favorite_activities')) {
      aL = requestor.favorite_activities.length;
      for (let a = 0; a < aL; a++) {
        screenStatus('Loading Favorites', ((a / aL) * 100), ((aL / 40) + .75));
        let this_activity = requestor.favorite_activities[a];
        let this_row = await addRow(this_activity, 'main', null, null, sectionSort, sectionName, sectionColor, sectionIcon, 'Favorite');
        if (this_row) { returnArray.push(this_row); }
      }
    }
    else {
      requestor.favorite_activities = [];
    }

    /*
    // Also add anything that you've used 3 or more times recently
    // Get Recent history
    // ({ '** HISTORY **': (activityHistory || 'no history found') });
    if (!('favorite_blocked' in requestor)) { requestor.favorite_blocked = []; }
    sectionSort = '**2a';
    sectionName = `${requestor.name.first.trim()}'${requestor.name.first.trim().slice(-1) === 's' ? '' : 's'} frequently used`;
    sectionColor = '#4bb491';
    sectionIcon = 'https://ava-icons.s3.amazonaws.com/icons8-star-half-empty-50.png';
    activityHistory = await getActivityLog(pPerson);
    let hL = Object.keys(activityHistory).length;
    let h = 0;
    for (const hActivity in activityHistory) {
      h++;
      if ((activityHistory[hActivity].length > 4) &&
        !(requestor.favorite_activities.includes(hActivity)) &&
        !(requestor.favorite_blocked.includes(hActivity))) {
        screenStatus('Frequently Used', ((h / hL) * 100), ((hL / 40) + .75));
        let this_row = await addRow(hActivity, 'main', null, null, sectionSort, sectionName, sectionColor, sectionIcon, 'History');
        if (this_row) { returnArray.push(this_row); }
      }
    }
    */

    // ({ '** PRIORITIES **': (requestor.priority_activities || 'no priority activities') });
    if (requestor.hasOwnProperty('priority_activities')) {
      sectionSort = '**2b';
      sectionName = `${requestor.name.first.trim()}'${requestor.name.first.trim().slice(-1) === 's' ? '' : 's'} priorities`;
      sectionColor = "#a0985f";
      sectionIcon = 'https://ava-icons.s3.amazonaws.com/icons8-idea-sharing-64.png';
      let aL = requestor.priority_activities.length;
      for (let a = 0; a < aL; a++) {
        screenStatus('Priority Items', ((a / aL) * 100), ((aL / 40) + .75));
        let this_activity = requestor.priority_activities[a];
        let this_row = await addRow(this_activity, 'main', null, null, sectionSort, sectionName, sectionColor, sectionIcon, 'Priorities');
        if (this_row) { returnArray.push(this_row); }
      }
    }

    // Get all Groups this person is associated with
    let neededGroups = [];
    requestor.groups.forEach(e => {
      if (e in groupObj) { groupList.push(groupObj[e]); }
      else { neededGroups.push(e); }
    });
    if (neededGroups.length > 0) {
      let addGroupList = await getGroupsPersonBelongsTo(neededGroups);
      addGroupList.forEach(c => {
        groupList.push(c);
        groupObj[c.group_id] = c;
      });
    }
    groupList.sort((a, b) => {
      if (a.group_id < b.group_id) { return -1; }
      else { return 1; }
    });
    // ('** GROUPS **');
    let allowDuplicates = false;
    let gL = groupList.length;
    for (let g = 0; g < gL; g++) {
      let this_group = groupList[g];
      sectionSort = 'ZZZ';
      sectionName = `Common activities for the ${this_group.name}${!this_group.name.includes('roup') ? ' group' : ''}`;
      sectionColor = stringToColor(sectionName);
      sectionIcon = AVAIcon;
      // (`Checking group ${this_group.group_id} (${this_group.name}): ${(this_group.common_activities || 'no common activities')}`);
      if (!this_group.hasOwnProperty('common_activities')) { continue; }
      for (let a = 0; a < this_group.common_activities.length; a++) {
        let aL = this_group.common_activities.length;
        screenStatus(`Common activities for ${this_group.name}`, ((a / aL) * 100), ((aL / 40) + .75));
        let this_activity = this_group.common_activities[a];
        let overrideColor = '';
        let overrideIcon = '';
        if (!allowDuplicates && duplicateCheck.includes(this_activity)) {   // this_activity is already loaded
          continue;
        }
        if (this_activity.startsWith('~~')) {
          if (this_activity.includes('~[adopt=')) {
            let [, oGroup,] = this_activity.split(/~\[adopt=|\]/g);
            let oClient;
            if (oGroup.includes('//')) { [oClient, oGroup] = oGroup.split('//'); }
            else { oClient = masterClient; }
            let oGroupRec = await getGroup(oGroup, oClient);
            if (oGroupRec && oGroupRec.common_activities) {
              this_group.common_activities.splice(a, 1, ...oGroupRec.common_activities);
              a--;
            }
            continue;
          }
          if (this_activity.includes('~~duplicate=OK') || this_activity.includes('~[duplicate=OK]')) {
            allowDuplicates = true;
            this_activity = this_activity.replace('~~duplicate=OK', '');
            this_activity = this_activity.replace('~[duplicate=OK]', '');
          }
          else { allowDuplicates = false; }
          if (this_activity.includes('~[color=')) {
            let [front, oColor, back] = this_activity.split(/~\[color=|\]/g); 
            overrideColor = oColor;
            this_activity = front;
            if (back) { this_activity += back; };
          }
          if (this_activity.includes('~[icon=')) {
            let [front, oIcon, back] = this_activity.split(/~\[icon=|\]/g);
            overrideIcon = oIcon;
            this_activity = front;
            if (back) { this_activity += back; };
          }
          let sectionKeys = this_activity.split('~~');
          if (sectionKeys.length > 2) {
            sectionSort = sectionKeys[1];
            sectionName = sectionKeys[2];
          }
          else {
            sectionSort = sectionKeys[1];
            sectionName = sectionKeys[1];
          }
          if (sectionName.startsWith('section=')) { 
            sectionName = sectionName.split(/=(.*)/)[1];
          }
          if (sectionName.startsWith('submenu=')) {
            let subName = sectionName.split(/=(.*)/)[1];
            let subOverrides = {};
            if (overrideColor) { subOverrides.color = overrideColor; }
            if (overrideIcon) { subOverrides.icon = overrideIcon; }
            let currentMenu = menuStructure.length - 1;
            let this_section = menuStructure[currentMenu].currentSection;
            if (alreadyInSection(this_section, `submenu.${subName}`)) { continue; }
            let subMenuObj = {
              client_id: masterClient,
              event_id: `submenu.${subName}`,
              parent: ((currentMenu === 0) ? null : menuStructure[currentMenu - 1].menuName),
              parent_name: ((currentMenu === 0) ? null : menuStructure[currentMenu - 1].menuName),
              menu_name: subName
            };
            returnArray.push({
              menu_name: menuStructure[currentMenu].menuName,
              sort_key: `${sectionDetails[this_section].sort_key}-${numberOfRows + 1000}`,
              section_name: this_section,
              section_color: sectionDetails[this_section].color,
              section_icon: sectionDetails[this_section].icon,
              row_color: sectionDetails[this_section].color,
              activity_code: `submenu.${subName}`,
              activity_name: await resolveVariables(subName, { client_id: masterClient, patient_id: pPerson, user_id: pPerson }),
              row_type: 'event',
              default_value: null,
              parent_menu: ((currentMenu === 0) ? null : menuStructure[currentMenu - 1].menuName),
              child_menu: subName,
              reason: `Group ${this_group.group_id}`,
              last_used: -1,
              is_favorite: false,
              subMenu_data: subMenuObj
            });
            await addRow(
              `submenu.${subName}`,   // activity_code
              menuStructure[currentMenu].menuName,                  // this menu_id                      
              subMenuObj.parent,                    // parent menu_id
              subMenuObj.parent_name,               // parent menu name
              sectionDetails[this_section].sort_key,           // pSectionSort
              this_section,                                // pSectionName
              sectionDetails[this_section].color,          // pSectionColor
              sectionDetails[this_section].icon,          // pSectionIcon
              `Group ${this_group.group_id}`              // pReason
            );
            menuStructure.push({ menuName: subName, currentSection: subName });
            let subActivities = [];
            let nextA;
            for (let s = a + 1; ((s < aL) && (this_group.common_activities[s] !== '~~end_submenu')); s++) {
              subActivities.push(this_group.common_activities[s]);
              nextA = s;
            }
            subMenuObj = {
              client_id: masterClient,
              event_id: subName,
              parent: menuStructure[currentMenu].menuName,
              parent_name: menuStructure[currentMenu].menuName,
              menu_name: subName
            };
            let subLines = await handleSubMenu(subMenuObj, subActivities, subOverrides);
            returnArray.push(...subLines);
            menuStructure.pop();
            sectionName = menuStructure[menuStructure.length - 1].currentSection;
            a = nextA + 1;
            continue;
          }
          else {
            menuStructure[menuStructure.length - 1].currentSection = sectionName;
          }
          if (!(sectionName in sectionDetails)) {
            [sectionColor, sectionIcon] = await getCustomizations(sectionName);
            if (overrideColor) { sectionColor = overrideColor; }
            if (overrideIcon) { sectionIcon = overrideIcon; }
            sectionDetails[sectionName] = {
              color: sectionColor,
              icon: sectionIcon,
              sort_key: sectionSort
            };
          }
        }
        else {
          if (!(sectionName in sectionDetails)) {
            [sectionColor, sectionIcon] = await getCustomizations(sectionName);
            if (overrideColor) { sectionColor = overrideColor; }
            if (overrideIcon) { sectionIcon = overrideIcon; }
            sectionDetails[sectionName] = {
              color: sectionColor,
              icon: sectionIcon,
              sort_key: sectionSort
            };
          }
          let currentMenu = menuStructure.length - 1;
          if (!alreadyInSection(sectionName, this_activity)) {
            let this_row = await addRow
              (this_activity,                                 // pActivity
                menuStructure[currentMenu].menuName,        // pMenu
                (currentMenu === 0 ? null : menuStructure[currentMenu - 1].menuName),   // pParent
                null,                                           // pParentName
                sectionDetails[sectionName].sort_key,           // pSectionSort
                sectionName,                                // pSectionName
                sectionDetails[sectionName].color,          // pSectionColor
                sectionDetails[sectionName].icon,          // pSectionIcon
                `Group ${this_group.group_id}`              // pReason
              );
            if (this_row) {
              returnArray.push(this_row);
              duplicateCheck.push(this_activity);
            }
          }
        }
      }
    }

    // Add sort key where needed
    for (let ndx = 0; ndx < returnArray.length; ndx++) {
      let row = returnArray[ndx];
      if (row.sort_key.startsWith('#need-')) {
        let sData = {};
        if (row.section_name in sectionDetails) {
          sData = sectionDetails[row.section_name];
        }
        else {
          let [customColor, customIcon] = await getCustomizations(row.section_name);
          sData = {
            sort_key: `Z1~${row.section_name}`,
            color: customColor,
            icon: customIcon
          };
        }
        returnArray[ndx].sort_key = `${sData.sort_key}${row.sort_key.substring(5)}`;
        returnArray[ndx].section_color = sData.color;
        returnArray[ndx].row_color = sData.color;
        returnArray[ndx].section_icon = sData.icon;
      };
    };

    // Sort by sort_key
    returnArray.sort((a, b) => {
      if (a.sort_key > b.sort_key) { return 1; }
      else { return -1; }
    });

    // save for easy retieval next time
    saveMenu(pPerson, returnArray);
    return returnArray;
  }

  async function saveMenu(pPerson, pMenu) {
    await dbClient
      .update({
        Key: {
          person_id: pPerson
        },
        UpdateExpression: "set AVA_main_menu = :m",
        ExpressionAttributeValues: {
          ":m": pMenu
        },
        TableName: "AVAMenu"
      })
      .promise()
      .catch(error => {
        clt({ 'Menu not updated. Error is': error });
      });
  }

  async function getCustomizations(pName) {
    if (pName in customObj) { return [customObj[pName].color, customObj[pName].icon]; }
    let cRec = await dbClient
      .get({
        Key: {
          client_id: masterClient,
          custom_key: pName
        },
        TableName: "Customizations",
      })
      .promise()
      .catch(error => {
        cl(`Caught error reading Customizations.Error is: ${error} 
                    with client = ${masterClient} and custom_key = ${pName} `);
      });
    if (recordExists(cRec)) {
      customObj[pName] = {
        color: cRec.Item.color || stringToColor(pName),
        icon: cRec.Item.icon || AVAIcon
      };
      return [cRec.Item.color || stringToColor(pName), cRec.Item.icon || AVAIcon];
    }
    else {
      customObj[pName] = {
        color: stringToColor(pName),
        icon: AVAIcon
      };
      return [stringToColor(pName), AVAIcon];
    }
  }

  /*
  async function getActivityLog(pPerson) {
    let aRecs = await dbClient
      .query({
        KeyConditionExpression: 'user_id = :p',
        ExpressionAttributeValues: { ':p': pPerson },
        TableName: "ActivityLog",
        ScanIndexForward: false,
        Limit: 20
      })
      .promise()
      .catch(error => {
        cl({ 'Error reading ActivityLog': error });
      });
    let history = {};
    // ({ 'Activity Log query got:': aRecs });
    if (recordExists(aRecs)) {
      let aL = aRecs.Items.length;
      for (let a = 0; a < aL; a++) {
        if (aRecs.Items[a].activity_code in history) {
          history[aRecs.Items[a].activity_code].push(aRecs.Items[a].timestamp);
        }
        else {
          history[aRecs.Items[a].activity_code] = [aRecs.Items[a].timestamp];
        }
      }
      return history;
    }
    else { return []; }
  }
  */

  async function addRow(pActivity, pMenu, pParent, pParentName, pSectionSort, pSectionName, pSectionColor, pSectionIcon, pReason) {
    let activityRec = await getActivity(pActivity);
    if (Object.keys(activityRec).length === 0) {
      // (`rejecting ${pActivity} - not found in Activities table`);
      return false;
    };
    // (`added ${pActivity} to ${pSectionName} `);
    let last_used = ((activityRec.activity_code in activityHistory) ?
      Math.max(...activityHistory[activityRec.activity_code]) :
      -1
    );
    numberOfRows++;
    let aClient = activityRec.client_id;
    let [aType, aCode] = activityRec.activity_code.split('.');
    if (aType.includes('//')) {
      [aClient, aType] = aType.split('//');
    }
    let favorite = (pReason === 'History') || requestor.favorite_activities.includes(activityRec.activity_code);
    let pSort;
    if (activityRec.section_name && !favorite) {
      pSort = `#need-${numberOfRows}`;
    }
    else {
      pSort = `${pSectionSort}-${numberOfRows}`;
    }
    activityRec.code = activityRec.activity_code;
    return {
      activity_rec: activityRec,
      code: activityRec.activity_code,
      menu_name: pMenu,
      sort_key: pSort,
      section_name: (!favorite && activityRec.section_name) || pSectionName,
      section_color: pSectionColor,
      section_icon: pSectionIcon,
      row_color: pSectionColor,
      activity_code: activityRec.activity_code,
      activity_name: await resolveVariables(activityRec.name, { client_id: masterClient, patient_id: pPerson, user_id: pPerson }),
      row_type: activityRec.type,
      default_value: activityRec.validation?.default_value || null,
      parent_menu: pParent,
      child_menu: ((aType === 'event') ? aCode : null),
      reason: pReason,
      last_used: last_used,
      is_favorite: favorite,
      subMenu_data: ((aType !== 'event')
        ? null
        : {
          client_id: aClient,
          event_id: aCode,
          parent: pMenu,
          parent_name: pSectionName,
          menu_name: activityRec.name
        }
      )
    };
  }

  async function getActivity(pActivityCode) {
    if (pActivityCode in activityObj) { return activityObj[pActivityCode]; }
    let pClient = masterClient;
    let addClient = false;
    let overrideDefault, overrideTitle;
    let parts = pActivityCode.split('~[');
    let pActivity = parts[0];
    for (let p = 1; p < parts.length; p++) {
      let [iType, iData] = parts[p].split(/[=\]]/);
      switch (iType) {
        case 'default': {
          overrideDefault = await resolveVariables(iData, { client_id: masterClient, patient_id: pPerson, user_id: pPerson });
          break;
        }
        case 'title': {
          overrideTitle = await resolveVariables(iData, { client_id: masterClient, patient_id: pPerson, user_id: pPerson });;
          break;
        }
        case 'env': {
          if ((parts[p].toLowerCase() === 'test') && (!['T', 'L'].includes(ava_env))) { return {}; }
          else if ((parts[p].toLowerCase() === 'dev') && (ava_env !== 'D')) { return {}; }
          break;
        }
        case 'vers':
        case 'rel':
        case 'version':
        case 'release': {
          let checkVer = makeVersion(iData);
          if ((checkVer >= 9999) && (!['T', 'L'].includes(ava_env))) { return {}; }
          if ((parts[p].includes('<') && (ava_version_number >= checkVer))
            || (parts[p].includes('>') && (ava_version_number <= checkVer))
            || (parts[p].includes('=') && (checkVer !== ava_version_number))) {
            activityObj[pActivityCode] = {};
            return {};
          }
          break;
        }
        case 'date': {
          let checkDate = makeNumber(iData);
          if ((parts[p].includes('<') && (todays_numeric_date >= checkDate))
            || (parts[p].includes('>') && (todays_numeric_date <= checkDate))
            || (parts[p].includes('=') && (checkDate !== todays_numeric_date))) {
            activityObj[pActivityCode] = {};
            return {};
          }
          break;
        }
        default: { break; }
      }
    }
    if (pActivity.includes('//')) {
      [pClient, pActivity] = pActivity.split('//');
      addClient = true;
    }
    let aRecs = await dbClient
      .get({
        Key: {
          client_id: pClient,
          activity_code: pActivity
        },
        TableName: 'Activities'
      })
      .promise()
      .catch(error => {
        clt(`Error reading Activities is ${error}.`);
      });
    if (recordExists(aRecs)) {
      if (addClient) { aRecs.Item.activity_code = `${pClient}//${pActivity}`; };
      if (overrideDefault) {
        if (!('validation' in aRecs.Item)) { aRecs.Item.validation = {}; }
        aRecs.Item.validation.default_value = overrideDefault;
      }
      if (overrideTitle) {
        aRecs.Item.name = overrideTitle;
      }
      activityObj[pActivityCode] = aRecs.Item;
      return aRecs.Item;
    }
    activityObj[pActivityCode] = {};
    return {};
  }

  async function getGroupsPersonBelongsTo(neededGroupArray) {
    // ({ 'in getGroupsPersonBelongsTo': { pPerson } });
    let batchGetRequest = {
      RequestItems: {
        'Groups': {
          Keys: []
        }
      }
    };

    // requestor.groups was unexpectedly found to have duplicate entries in one instance
    // When that happened, this batchGetRequest would fail and no menu was rendered at all
    // the code "[...new Set(requestor.groups)]" assures that unique values only are considered
    [...new Set(neededGroupArray)].forEach(g => {
      batchGetRequest.RequestItems.Groups.Keys.push(
        {
          client_id: masterClient,
          group_id: g
        }
      );
    });
    let groupRecs = await dbClient
      .batchGet(batchGetRequest)
      .promise()
      .catch(error => {
        clt({ 'Bad get on Groups - caught error is': error });
      });
    if (groupRecs && ('Responses' in groupRecs)) {
      return groupRecs.Responses.Groups;
    }
    else { return []; }
  }

};    // end
